import React from 'react';
import LogoImageSrc from '../images/logo.svg';

const SimpleHeader = () => {
  return (
    <header className="container-xl py-8">
      <img src={LogoImageSrc} alt="Logo" className="h-10 object-contain" />
    </header>
  );
};

export default SimpleHeader;
