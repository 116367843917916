import * as React from 'react';
import DefaultLayout from './DefaultLayout';
import SimpleLayout from './SimpleLayout';

const Layout = ({ children, location, pageContext }) => {
  if (pageContext.layout === 'simple') {
    return (
      <SimpleLayout location={location}>
        {children}
      </SimpleLayout>
    );
  }

  return (
    <DefaultLayout location={location}>
      {children}
    </DefaultLayout>
  );
};

export default Layout;
