import * as React from 'react';

import Link from '../components/Link';

import GoogleIcon from '../components/icons/GoogleIcon';
import LinkedInIcon from '../components/icons/LinkedInIcon';
import YoutubeIcon from '../components/icons/YoutubeIcon';

import { useLayoutState } from './Context';

import useNavigation from '../hooks/useNavigation';
import useTranslation from '../hooks/useTranslation';

import LogoImageSrc from '../images/plusmeta_Logo_Quanos_web_weiß_svg.svg';

const Footer = () => {
  const { locale } = useLayoutState();
  const { getLink } = useNavigation(locale);
  const { t } = useTranslation('navigation', locale);

  return (
    <>
      <footer className="bg-indigo-500 text-white pt-16 pb-8">
        <div className="container-xl">
          <div className="row">
            <div className="col-full lg:col-3">
              <div>
                <img src={LogoImageSrc} alt="Logo" className="h-16 object-contain" />
              </div>
              <p className="typo-body mt-8">
                {t('description')}
              </p>
              <ul className="flex mt-8">
                <li className="mr-6">
                  <Link href="https://de.linkedin.com/company/plusmeta">
                    <LinkedInIcon className="h-8 w-8" />
                  </Link>
                </li>
                <li className="mr-6">
                  <Link href="https://www.youtube.com/channel/UCxaIxBItWbv4uvW7YnoO4Lw">
                    <YoutubeIcon className="h-8 w-8" />
                  </Link>
                </li>
                <li>
                  <Link href="https://g.page/plusmeta">
                    <GoogleIcon className="h-8 w-8" />
                  </Link>
                </li>
              </ul>
              <ul className="mt-8">
                <li>
                  <Link href={getLink('/impressum/')} className="typo-body no-underline">
                    {t('Impressum')}
                  </Link>
                </li>
                <li className="mt-1">
                  <Link href={getLink('/datenschutzerklaerung/')} className="typo-body no-underline">
                    {t('Datenschutzerklärung')}
                  </Link>
                </li>
                <li className="mt-1">
                  <Link href={getLink('/haftungsausschluss/')} className="typo-body no-underline">
                    {t('Haftungsausschuss')}
                  </Link>
                </li>
                <li className="mt-1">
                  <Link href={getLink('/bildnachweise/')} className="typo-body no-underline">
                    {t('Bildnachweis')}
                  </Link>
                </li>
                <li className="mt-1">
                  <Link href={getLink('/online-streitbeilegung/')} className="typo-body no-underline">
                    {t('Online-Streitbeilegung')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="hidden lg:block col-full lg:col-8 lg:ml-1/12">
              <div className="row">
                <div className="col-full sm:col-6 lg:col-4 mt-8 lg:mt-0">
                  <h3 className="typo-blockquote font-display text-white">
                    <Link href={getLink('/software/')} className="no-underline">
                      {t('Software')}
                    </Link>
                  </h3>
                  <ul className="mt-4">
                    <li className="mt-2">
                      <Link href={getLink('/software/#integrationen')} className="typo-body no-underline">
                        {t('Integrationen')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/software/#software')} className="typo-body no-underline">
                        {t('Software')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/software/#plugins')} className="typo-body no-underline">
                        {t('Plugins')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/software/#opensource')} className="typo-body no-underline">
                        {t('Open Source')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/software/#datensicherheit')} className="typo-body no-underline">
                        {t('Datensicherheit')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-full sm:col-6 lg:col-4 mt-8 lg:mt-0">
                  <h3 className="typo-blockquote font-display text-white">
                    <Link href={getLink('/unternehmen/')} className="no-underline">
                      {t('Unternehmen')}
                    </Link>
                  </h3>
                  <ul className="mt-4">
                    <li>
                      <Link href={getLink('/unternehmen/#ueber-uns')} className="typo-body no-underline">
                        {t('Über uns')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/unternehmen/#team')} className="typo-body no-underline">
                        {t('Unser Team')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/unternehmen/#karriere')} className="typo-body no-underline">
                        {t('Karriere')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/unternehmen/#forschung')} className="typo-body no-underline">
                        {t('Forschung')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/unternehmen/#mitgliedschaften')} className="typo-body no-underline">
                        {t('Mitgliedschaften')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/unternehmen/#auszeichnungen')} className="typo-body no-underline">
                        {t('Auszeichnungen')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/unternehmen/#partner')} className="typo-body no-underline">
                        {t('Partner')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-full sm:col-6 lg:col-4 mt-8 lg:mt-0">
                  <h3 className="typo-blockquote font-display text-white">
                    <Link href={getLink('/support/')} className="no-underline">
                      {t('Service & Support')}
                    </Link>
                  </h3>
                  <ul className="mt-4">
                    <li>
                      <Link href={getLink('/support/#anwenderportal')} className="typo-body no-underline">
                        {t('Anwenderportal')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/support/#dokumentation')} className="typo-body no-underline">
                        {t('Dokumentation')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/support/#schulungen')} className="typo-body no-underline">
                        {t('Schulungen')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/support/#videos')} className="typo-body no-underline">
                        {t('plusmeta-Videos')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/kontakt/')} className="typo-body no-underline">
                        {t('Kontakt')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-full sm:col-6 lg:col-4 mt-8">
                  <h3 className="typo-blockquote font-display text-white">
                    <Link href={getLink('/loesungen/')} className="no-underline">
                      {t('Lösungen')}
                    </Link>
                  </h3>
                  <ul className="mt-4">
                    <li>
                      <Link href={getLink('/loesungen/#ueberblick')} className="typo-body no-underline">
                        {t('Überblick')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/loesungen/#unser-vorgehen')} className="typo-body no-underline">
                        {t('Unser Vorgehen')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/loesungen/#standards')} className="typo-body no-underline">
                        {t('Standards')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-full sm:col-6 lg:col-4 mt-8">
                  <h3 className="typo-blockquote font-display text-white">
                    <Link href={getLink('/branchen/')} className="no-underline">
                      {t('Branchen')}
                    </Link>
                  </h3>
                  <ul className="mt-4">
                    <li>
                      <Link href={getLink('/branchen/#success-stories')} className="typo-body no-underline">
                        {t('Success Stories')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/branchen/#kunden')} className="typo-body no-underline">
                        {t('Kunden')}
                      </Link>
                    </li>
                    <li className="mt-2">
                      <Link href={getLink('/branchen/#branchen')} className="typo-body no-underline">
                        {t('Branchen')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <small className="typo-small">
              &copy;2024 plusmeta GmbH - a Quanos Company
            </small>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
