import React from 'react';

import { useLayoutState } from './Context';
import useNavigation from '../hooks/useNavigation';
import useTranslation from '../hooks/useTranslation';

import LogoImageSrc from '../images/plusmeta_Logo_Quanos_web_weiß_svg.svg';
import Link from '../components/Link';

const SimpleFooter = () => {
  const { locale } = useLayoutState();
  const { getLink } = useNavigation(locale);
  const { t } = useTranslation('navigation', locale);

  return (
    <footer className="bg-indigo-500 text-white py-8">
      <div className="container-xl">
        <div className="flex flex-col sm:flex-row justify-between">
          <div>
            <div>
              <img src={LogoImageSrc} alt="Logo" className="h-10 object-contain" />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-x-16 mt-8 sm:mt-0">
            <Link href={getLink('/kontakt/')} className="typo-body no-underline">
              {t('Kontakt')}
            </Link>
            <Link href={getLink('/impressum/')} className="typo-body no-underline">
              {t('Impressum')}
            </Link>
            <Link href={getLink('/datenschutzerklaerung/')} className="typo-body no-underline">
              {t('Datenschutzerklärung')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SimpleFooter;
