import React from 'react';
import SimpleHeader from './SimpleHeader';
import SimpleFooter from './SimpleFooter';
import { LayoutProvider } from './Context';

const DefaultLayout = ({ children, location }) => {
  return (
    <LayoutProvider location={location}>
      <SimpleHeader location={location} />
      <main>
        {children}
      </main>
      <SimpleFooter />
    </LayoutProvider>
  );
};

export default DefaultLayout;
